<template>
  <div id="pc" ref="scrollElement" @scroll="handleScroll">
    <navBar
      @navChang="navChang"
      id="navigation"
      ref="navbar"
      :navRed="navRed"
    ></navBar>
    <banner-view id="bannerView"></banner-view>
    <new-center id="newCenter"></new-center>
    <hot-game id="hotGame"></hot-game>
    <new-game id="newGame"></new-game>
    <rank-game id="rankGame"></rank-game>
    <about-us id="aboutUs"></about-us>
    <download-game id="downloadGame"></download-game>
    <!-- 悬浮弹窗 -->
    <div class="fixed" id="btnScrollToTop">
      <!-- <div style="cursor: pointer;">
       
        <div class="fixed-li" style="margin-right:0">
          <div style="width: 8px;height: 13px;">
            <img src="../../src/assets/image/icon/80.png" alt="" style="display: block;width: 100%; height: 100%;">
          </div>
          <div style="color: #CC5341;font-size: 20px;font-weight: bold;">进入引擎</div>
        </div>
       
      </div> -->

      <div>
        <a href="http://www.qn1000a.com/#/" target="_blank">
          <div class="fixed-li" @click="basescroll('#hotGame')">
            <div class="fixed-li_4"></div>
            <div>进入引擎</div>
          </div>
        </a>
      </div>

      <div>
        <div class="fixed-li" @click="basescroll('#hotGame')">
          <div class="fixed-li_1"></div>
          <div>热门游戏</div>
        </div>
      </div>
      <div>
        <div class="fixed-li" @click="downloadA(kfUrl)">
          <div class="fixed-li_kf"></div>
          <div>客服</div>
        </div>
      </div>
      <div>
        <div class="fixed-li" @click="basescroll('#downloadGame')">
          <div class="fixed-li_2"></div>
          <div>下载盒子</div>
        </div>
      </div>
      <div class="fixed-li" @click="scrollToTop">
        <div class="fixed-li">
          <div class="fixed-li_3"></div>
          <div>TOP</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HotGame from "./pc_components/hotGame.vue";
import navBar from "./pc_components/navBar.vue";
import NewCenter from "./pc_components/newCenter.vue";
import NewGame from "./pc_components/newGame.vue";
import RankGame from "./pc_components/rankGame.vue";
import AboutUs from "./pc_components/aboutUs.vue";
import DownloadGame from "./pc_components/downloadGame.vue";
import BannerView from "./pc_components/bannerView.vue";
import { downloadA,kfUrl } from "@/utils/download";
export default {
  name: "pc",
  components: {
    navBar,
    NewCenter,
    HotGame,
    NewGame,
    RankGame,
    AboutUs,
    DownloadGame,
    BannerView,
  },
  data() {
    return {
      navRed: 0,
      navbarHeight: 0,
      showNewCenter: true,
      kfUrl:kfUrl
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.scrollFunction);
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {},
  methods: {
    downloadA,
    handleScroll() {
      if (
        (document.documentElement.scrollTop < 600 ||
          document.documentElement.scrollTop == 600) &&
        document.getElementById("bannerView")
      ) {
        this.navRed = 0;
      } else if (
        document.documentElement.scrollTop > 600 &&
        document.documentElement.scrollTop <= 1350 &&
        document.getElementById("newCenter")
      ) {
        this.navRed = 1;
      } else if (
        document.documentElement.scrollTop > 1350 &&
        document.documentElement.scrollTop <= 1800 &&
        document.getElementById("hotGame")
      ) {
        this.navRed = 2;
      } else if (
        document.documentElement.scrollTop > 1800 &&
        document.documentElement.scrollTop <= 3200 &&
        document.getElementById("newGame")
      ) {
        this.navRed = 3;
      } else if (
        document.documentElement.scrollTop > 3700 &&
        document.documentElement.scrollTop <= 4500 &&
        document.getElementById("aboutUs")
      ) {
        this.navRed = 5;
      } else if (
        document.documentElement.scrollTop > 4500 &&
        document.documentElement.scrollTop <= 4800 &&
        document.getElementById("downloadGame")
      ) {
        this.navRed = 6;
      }
      // console.log(this.navRed);
      // console.log(document.documentElement.scrollTop, "1");
    },
    navChang(id) {
      // console.log(id, "id");  id是对应的页面模块
      this.basescroll(id);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollFunction() {
      if (
        (document.body.scrollTop > 600 ||
          document.documentElement.scrollTop > 600) &&
        document.getElementById("btnScrollToTop")
      ) {
        document.getElementById("btnScrollToTop").style.visibility = "visible";
      } else {
        if (document.getElementById("btnScrollToTop"))
          document.getElementById("btnScrollToTop").style.visibility = "hidden";
      }
      // console.log(document.documentElement.scrollTop, "2")
    },
    basescroll(id) {
      let dome = document.querySelector(id);
      // console.log(dome);
      dome.scrollIntoView();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/pc";
.red {
  color: red;
}

.fixed {
  // width: 1.052083rem /* 202/192 */ /* 68/80 */;
  // height: 1.234375rem /* 237/192 */ /* 236/80 */;
  width: 116px;
  height: 348px;
  position: fixed;
  // right: 0.083333rem /* 16/192 */;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: #f6f5f4;
  box-shadow: 0px 0.0375rem 0.0625rem 1px rgba(124, 124, 124, 0.6);
  border-radius: 0.052083rem /* 10/192 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  box-sizing: border-box;
}

.fixed > div {
  width: 100px;
  height: 87px;
  flex: 1;
  color: #393333;
  // font-size: 0.175rem /* 14/80 */;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-left: 1px;
  // margin-right: 12px;
  // margin-left: 12px;
  cursor: pointer;
  border-bottom: 1px solid #d2d2d2;
  box-sizing: border-box;
}
.fixed > div:nth-child(3) {
  // border: none;
}

.fixed .fixed-li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: 0.125rem /* 24/192 */;
  font-size: 16px;
  // margin-right: 0.182292rem /* 35/192 */;
}

.fixed .fixed-li > div:nth-child(1) {
  margin: 0 auto;
  background-size: cover;
}
.fixed .fixed-li > div:nth-child(2) {
  // margin-left: 0.052083rem /* 10/192 */;
  // margin-left: 10px;
  // margin: 0 auto;
}
.fixed .fixed-li_1 {
  // width: 0.161458rem /* 31/192 */;
  // height: 0.161458rem /* 31/192 */;
  width: 31px;
  height: 31px;
  background: url("../assets/image/icon/23.png") no-repeat center;
}

.fixed .fixed-li_2 {
  // width: 0.135417rem /* 26/192 */;
  // height: 0.130208rem /* 25/192 */;
  width: 26px;
  height: 25px;
  background: url("../assets/image/icon/24.png") no-repeat center;
  background-image: 24px 22px;
  margin: 0 auto;
}
.fixed .fixed-li_kf {
  // width: 0.135417rem /* 26/192 */;
  // height: 0.130208rem /* 25/192 */;
  width: 26px;
  height: 25px;
  background: url("../assets/image/icon/kf.png") no-repeat center;
  background-image: 24px 22px;
  margin: 0 auto;
}
.fixed .fixed-li_3 {
  // width: 0.140625rem /* 27/192 */;
  // height: 0.135417rem /* 26/192 */;
  width: 27px;
  height: 26px;
  background: url("../assets/image/icon/25.png") no-repeat center;
}
a {
  color: #cc5341;
  font-weight: bold;
}
.fixed .fixed-li_4 {
  // width: 0.140625rem /* 27/192 */;
  // height: 0.135417rem /* 26/192 */;
  width: 27px;
  height: 26px;
  background: url("../assets/image/icon/80.png") no-repeat center;
}
</style>
