var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"original",attrs:{"id":"downloadGame"}},[_c('div',{staticClass:"downloadGame max-width"},[_c('div',{staticClass:"downloadGame_title"},[_vm._v("下载盒子")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"xz_content",on:{"click":function($event){return _vm.iosTo()}}},[_vm._m(0),_c('div',{staticClass:"btn"},[_vm._v("下载ios版本")])]),_c('div',{staticClass:"xz_content",on:{"click":function($event){return _vm.azTo()}}},[_vm._m(1),_c('div',{staticClass:"btn"},[_vm._v("下载Android版本")])]),_c('div',{staticClass:"xz_content",on:{"click":_vm.PCTo}},[_vm._m(2),_c('div',{staticClass:"btn"},[_vm._v("下载电脑版本")])]),_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"footer"},[_vm._m(5),_c('div',{staticClass:"tex"},[_vm._v(" 健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 防受骗上当 适度游戏益脑 迷游戏伤身 合理安排时间 享受健康生活 ")]),_c('router-link',{attrs:{"to":"yinsi","target":"_blank"}},[_vm._v("《隐私政策》")])],1),(_vm.isToast)?_c('div',{staticClass:"toast"},[_vm._v("即将震撼来袭 敬请期待")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xz_img"},[_c('div',{staticClass:"xia_img"},[_c('img',{attrs:{"src":require("../../assets/image/icon/16.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xz_img"},[_c('div',{staticClass:"xia_img"},[_c('img',{attrs:{"src":require("../../assets/image/icon/17.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xz_img"},[_c('div',{staticClass:"xia_img",staticStyle:{"width":"0.63rem","height":"0.52rem"}},[_c('img',{attrs:{"src":require("../../assets/image/icon/pc.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xz_content"},[_c('div',{staticClass:"xz_img"},[_c('div',{staticClass:"xia_img",staticStyle:{"width":"0.677rem","height":"0.677rem"}},[_c('img',{attrs:{"src":require("../../assets/image/icon/xz.jpg"),"alt":""}})])]),_c('div',{staticClass:"btn",staticStyle:{"width":"1.5rem"}},[_vm._v("长按保存，扫码下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xz_content"},[_c('div',{staticClass:"xz_img"},[_c('div',{staticClass:"xia_img",staticStyle:{"width":"0.677rem","height":"0.677rem"}},[_c('img',{attrs:{"src":require("../../assets/image/icon/gzh.jpg"),"alt":""}})])]),_c('div',{staticClass:"btn",staticStyle:{"width":"1.5rem"}},[_vm._v("关注微信公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"tex"},[_vm._v("江阴千游网络科技有限公司")]),_c('div',{staticClass:"tex",staticStyle:{"margin-left":"0.69rem"}}),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_c('div',{staticClass:"tex",staticStyle:{"margin-left":"0.69rem"}},[_vm._v(" ICP备案号：苏ICP备2022030317号-8 ")])]),_c('div',{staticClass:"tex",staticStyle:{"margin-left":"0.69rem"}},[_vm._v(" 违规投诉/举报邮箱：jiankongzu2024@163.com ")])])
}]

export { render, staticRenderFns }