<template>
  <div id="aboutUs" class="original">
    <div class="aboutUs max-width">
      <div class="ab_title">商务合作</div>
      <div class="ab_content">
        <div class="content_box">
          <div class="contact_left">
            <div class="left_content">
              <div
                class="contact_title"
                style="border-radius: 0.052083rem 0 0 0"
              >
                商务合作
              </div>
              <div
                class="contact_content"
                style="height: 0.75rem; /* 150/192 */"
              >
                <img
                  src="@/assets/image/wap/icon/15.png"
                  alt=""
                  class="em_img"
                />
                <div class="content_text">邮箱</div>
                <div class="content_text">hanbin@qianyouqn.com</div>
              </div>
            </div>
            <div class="left_content">
              <div class="contact_title">微信公众号</div>
              <div class="contact_content" style="height: 1rem; /* 217/192 */">
                <img
                  src="@/assets/image/pc/aboutus/gzh.jpg"
                  alt=""
                  class="gzh_img"
                />
              </div>
            </div>
          </div>
          <div class="contact_right">
            <div class="contact_title">千年游戏盒子合作</div>
            <div class="content_item">
              <div
                class="content_item_box"
                v-for="(item, index) in aboutList"
                :key="index"
              >
                <div class="contact_item_title">{{ item.title }}</div>
                <div class="contact_item_content">
                  <img :src="item.qqimg" alt="" />
                  <span class="text">{{ item.qq }}</span>
                </div>
                <div class="contact_item_content">
                  <img :src="item.phoneimg" alt="" />
                  <span class="text">{{ item.phone }}</span>
                </div>
                <div class="contact_item_content">
                  <img :src="item.emailImg" alt="" />
                  <span class="text">{{ item.email }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexs: 0,
      aboutList: [
        {
          title: "千游商务小五",
          qq: "594241",
          phone: "15010805655",
          email: "hanbin@qianyouqn.com",
          qqimg: require("@/assets/image/icon/3.png"),
          phoneimg: require("@/assets/image/icon/12.png"),
          emailImg: require("@/assets/image/wap/icon/15.png"),
        },
        // {
        //   title: "千游商务阿成",
        //   qq: "649265011",
        //   phone: "18576468073",
        //   email: "shenzhuocheng@qianyouqn.com",
        //   qqimg: require("@/assets/image/icon/3.png"),
        //   phoneimg: require("@/assets/image/icon/12.png"),
        //   emailImg: require("@/assets/image/wap/icon/15.png"),
        // },
        {
          title: "千游商务豪杰",
          qq: "594843422",
          phone: "19852886295",
          email: "shihaojie@qianyouqn.com",
          qqimg: require("@/assets/image/icon/3.png"),
          phoneimg: require("@/assets/image/icon/12.png"),
          emailImg: require("@/assets/image/wap/icon/15.png"),
        },
        {
          title: "千游商务阿成",
          qq: "649265011",
          phone: "18576468073",
          email: "shenzhuocheng@qianyouqn.com",
          qqimg: require("@/assets/image/icon/3.png"),
          phoneimg: require("@/assets/image/icon/12.png"),
          emailImg: require("@/assets/image/wap/icon/15.png"),
        },
        {
          title: "千游商务小杰",
          qq: "1147125160",
          phone: "19042747467",
          email: "wangyoujie@qianyouqn.com",
          qqimg: require("@/assets/image/icon/3.png"),
          phoneimg: require("@/assets/image/icon/12.png"),
          emailImg: require("@/assets/image/wap/icon/15.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    changeNav(index) {
      this.indexs = index;
      this.aboutList[0].show = true;
    },
  },
};
</script>

<style scoped lang="scss">
.max-width {
  max-width: 1920px;
  margin: 0 auto;
}
.original {
  height: 3.74rem /* 718/192 */;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.aboutUs {
  height: 100%;
  background: url(../../assets/image/pc/bg/145.jpg) no-repeat;
  margin: 0 auto;
  .ab_title {
    text-align: center;
    color: #000;
    font-weight: bold;
    font-size: 0.208333rem /* 40/192 */;
    padding-top: 0.390625rem /* 75/192 */;
    padding-bottom: 0.390625rem /* 75/192 */;
  }
  .ab_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .content_box {
      width: 6.3125rem /* 1212/192 */;
      display: flex;
      background: #fff;
      border-radius: 0.052083rem;
      .contact_left {
        width: 1.25rem /* 240/192 */;
        height: 2.3rem /* 472/192 */;

        .contact_title {
          width: 100%;
          height: 0.270833rem /* 52/192 */;
          font-size: 0.09375rem /* 18/192 */;
          color: #fff;
          background: rgb(61, 61, 61);
          text-align: center;
          line-height: 0.270833rem /* 52/192 */;
          font-weight: bold;
        }
        .contact_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 0.072917rem /* 14/192 */;
          line-height: 0.12rem;
          .em_img {
            display: block;
            width: 0.177083rem /* 34/192 */;
          }
          .gzh_img {
            display: block;
            width: 0.786458rem /* 151/192 */;
          }
        }
      }
      .contact_right {
        flex: 1;
        height: 2.3rem;
        border-left: 0.005208rem /* 1/192 */ solid #ccc;
        .contact_title {
          width: 100%;
          height: 0.270833rem /* 52/192 */;
          font-size: 0.140625rem /* 27/192 */;
          color: #fff;
          background: #cc5341;
          text-align: center;
          line-height: 0.270833rem /* 52/192 */;
          border-radius: 0 0.052083rem 0 0;
          font-weight: bold;
        }
        .content_item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 5.0625rem /* 972/192 */;
          .content_item_box {
            width: calc(100% / 3);
            height: 1.02rem;
            border-right: 0.005208rem /* 1/192 */ solid #ccc;
            border-bottom: 0.005208rem /* 1/192 */ solid #ccc;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0.2rem;
            box-sizing: border-box;
            font-size: 0.09375rem /* 18/192 */;
            .contact_item_title {
              padding-left: 0.135417rem /* 26/192 */;
              display: flex;
              align-items: center;
              margin-bottom: 0.041667rem /* 8/192 */;
              color: #000;
              font-weight: bold;
            }
            .contact_item_content {
              color: #000;
              display: flex;
              align-items: center;
              margin-bottom: 0.041667rem /* 8/192 */;
              color: #000;
              .text {
                font-size: 0.072917rem /* 14/192 */;
              }
              img {
                display: block;
                width: 0.114583rem /* 22/192 */;
                margin-right: 0.052083rem /* 10/192 */;
              }
            }
          }
        }
        .content_item::after {
          content: "";
          width: 33%;
        }
        .content_item_box:nth-child(3) {
          border-right: none;
        }
        .content_item_box:nth-child(4) {
          border-bottom: none;
        }
        .content_item_box:nth-child(5) {
          border-bottom: none;
          // border-right: none;
        }
      }
    }
  }
}
</style>
