<template>
  <div id="navigation">
    <div class="wap_index-nav">
      <div class="wap_index-nav-box">
        <div class="img_nav"></div>

        <div class="wap_index-nav-right">
          <div class="wap_index-nav-box-1" ref="control">
            <div class="flex" ref="moveNav">
              <div class="nav-a"
                @click="index === navList.length - 2 ? goToWebsite() : index === navList.length - 1 ? goToGfsite() : navChoice(index)"
                v-for="(item, index) in navList" :key="index">
                <!-- <div class="nav-a"></div> -->
                <div class="nav_bgSize" :class="item.show ? `pop_navIco${index}1` : `pop_navIco${index}`"></div>
                <div :class="item.show ? 'sizeColor' : '' || index == sred ? 'sizeColor' : ''">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div @click="ispopShow" class="pop_img" :class="isShow ? 'active' : ''"></div>
      </div>
    </div>

    <!-- 导航栏弹窗 -->
    <transition enter-active-class="nav_pop_enter" leave-active-class="nav_pop_leave">
      <div v-if="popShow" class="nav_pop">
        <div class="nav_pop-box">
          <div class="pop_list" :class="popShow ? 'pop_listPlay' : ''">
            <div class="pop_list_li" @click="
              index === navList.length - 2
                ? goToWebsite()
                : index === navList.length - 1
                  ? goToGfsite()
                  : navChoice(index)
              " v-for="(item, index) in navList" :key="index" :class="index == navList.length - 1 ? 'borderNo' : ''">
              <div>
                <div :class="`navIco${index}`"></div>
              </div>
              <div :class="item.show
                ? 'sizeChoice'
                : '' || index == sred
                  ? 'sizeColor'
                  : ''
                ">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    activeRed: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      isShow: false,
      popShow: false,
      lastShow: false,
      scrollShow: false,
      sred: 0,
      navList: [
        {
          title: "首页",
          show: false,
          href: "#bannerView",
        },
        {
          title: "资讯中心",
          show: false,
          href: "#newCenter",
        },
        // {
        //   title: "热门游戏",
        //   show: false,
        //   href: "#hotGame",
        // },
        {
          title: "新游推荐",
          show: false,
          href: "#newGame",
        },
        // {
        //   title: "游戏排行",
        //   show: false,
        //   href: "#rankGame",
        // },
        {
          title: "商务合作",
          show: false,
          href: "#aboutUs",
        },
        {
          title: "下载盒子",
          show: false,
          href: "#downloadGame",
        },
        {
          title: "千年游戏引擎官网",
          show: false,
          href: "",
        },
        {
          title: "千年官方发布站",
          show: false,
          href: "",
        },
      ],
    };
  },
  created() { },
  mounted() { },
  watch: {
    activeRed: {
      handler(val) {
        this.navList.forEach((item) => {
          item.show = false;
        });

        this.sred = val;
        if (val == 2) {
          this.sred = 9;
        } else if (val == 3) {
          this.sred = 2;
        } else if (val == 4) {
          this.sred = 9;
        } else if (val == 5) {
          this.sred = 3;
        } else if (val == 6) {
          this.sred = 4;
        } else if (val == 7) {
          this.sred = 9;
        }

        this.navHar(this.sred);
      },
    },
  },
  methods: {
    goToWebsite() {
      window.location.href = "http://www.qn1000a.com/#/";
    },
    goToGfsite() {
      window.location.href = "https://www.qn2024.com/";
    },
    ispopShow() {
      this.popShow = !this.popShow;
      this.isShow = !this.isShow;
    },
    navHar(index) {
      let controlWidth = this.$refs.control.offsetWidth;
      let displacement = 0;
      if (index != 9) {
        displacement =
          this.$refs.moveNav.children[index].offsetLeft -
          controlWidth / 2 +
          this.$refs.moveNav.children[index].offsetWidth / 2;
      }

      if (index > 1) {
        this.$refs.control.scrollLeft = displacement;
      } else {
        this.$refs.control.scrollLeft = 0;
      }
    },
    // 导航弹窗 选中
    navChoice(index) {
      let controlWidth = this.$refs.control.offsetWidth;
      console.log(controlWidth, "controlWidth");
      let displacement =
        this.$refs.moveNav.children[index].offsetLeft -
        controlWidth / 2 +
        this.$refs.moveNav.children[index].offsetWidth / 2;
      if (index > 1) {
        this.$refs.control.scrollLeft = displacement;
      } else {
        this.$refs.control.scrollLeft = 0;
      }
      this.navList.forEach((item) => {
        item.show = false;
      });
      this.navList[index].show = true;
      console.log(index);

      this.$emit("navChang", index);
      this.popShow = false;
      this.isShow = false;
    },
    cNav(index) {
      let id = this.navList[index].href;
      this.$emit("navChang", id);
    },
  },
};
</script>

<style>
@import "../../assets/css/swiper-bundle.min.css";
</style>

<style lang="scss" scoped>
#navigation {
  // width: 100%;
  // height: 1.21rem;
  // position: sticky;
  // top: 0;
  z-index: 1000;
  // background: #000;
  // flex-shrink: 0;

  .wap_index-nav {
    position: fixed;
    top: 0rem
      /* 15/37.5 */
    ;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    /* margin: 0 auto; */
    width: 100%;
    // height: 1.21rem;
    padding-top: .4rem;
    background: rgba($color: #000000, $alpha: .32);
    backdrop-filter: blur(.0375rem);
    // background: #000;
    z-index: 1;
  }

  .wap_index-nav-box {
    width: 100%;
    height: 100%;
    padding: 0 .5rem;
    display: flex;
    // justify-content: space-between;
  }

  .img_nav {
    display: block;
    width: 0.72rem
      /* 27/37.5 */
    ;
    height: 0.72rem
      /* 27/37.5 */
    ;
    // background: url(../../assets/image/wap/icon/13.png) no-repeat center;
    background: url(../../assets/image/icon/logo.png) no-repeat center;
    background-size: cover;
    margin-bottom: .4rem;
  }

  .nav_pop {
    width: 100%;
    height: 94%;
    position: fixed;
    top: 1.5rem;
    left: 0;
    background: rgba(35, 28, 14, 0.9);
    z-index: 9999999999999999999999999;
    overflow: hidden;
  }

  .nav_pop .nav_pop-box {
    width: 16.5rem
      /* 330/20 */
    ;
    margin: 0.133333rem
      /* 80/37.5 */
      auto;
    width: 88%;
  }

  .nav_pop .nav_pop-box .close {
    padding-top: 0.693333rem
      /* 26/37.5 */
    ;
    padding-bottom: 1.066667rem
      /* 40/37.5 */
    ;
    display: flex;
    flex-direction: row-reverse;
  }

  .nav_pop .nav_pop-box .close img {
    display: block;
    width: 0.56rem
      /* 21/37.5 */
    ;
    height: 0.533333rem
      /* 20/37.5 */
    ;
  }

  .wap_index .wap_index-nav>div:nth-child(1) {
    width: 15rem;
    height: 100%;

    /* overflow: hidden; */
    overflow-x: auto;
    display: flex;
  }

  .wap_index .wap_index-nav>div:nth-child(1) a {
    width: 25%;
    font-size: 0.373333rem
      /* 14/37.5 */
    ;
    color: #fff;
    display: flex;
    align-items: center;
  }

  .nav_bgSize {
    margin-right: 0.213333rem
      /* 8/37.5 */
    ;
  }

  .wap_index-nav-right {
    flex: 0.92;
    position: relative;
  }

  .wap_index-nav-box-1 {
    width: 100%;
    top: .08rem
      /* 3/37.5 */
    ;
    position: absolute;
    // flex: 0.92;
    // height: 1.21rem;
    display: flex;
    // align-items: center;
    // background-color: pink;
    overflow-x: scroll;
    // padding-bottom: 0;

  }

  .wap_index-nav-box-1::-webkit-scrollbar {
    background-color: transparent;
  }

  .wap_index-nav-box-1>div>div {
    flex-shrink: 0;
  }

  .nav-a {
    color: #fff;
    font-size: 0.426667rem
      /* 16/37.5 */
    ;
    margin-right: 0.426667rem
      /* 16/37.5 */
    ;
    display: flex;
    align-items: center;
  }

  .flex>.nav-a:nth-child(6) {
    background: url(../../assets/image/wap/H5/11.gif) no-repeat center;
    background-size: cover;
    padding-right: 10px;
  }

  .flex>.nav-a:last-child {
    width: 5.24rem
      /* 234/37.5 */
    ;
    background: url(../../assets/image/wap/H5/wgf.png) no-repeat center;
    background-size: cover;
    padding-right: 10px;
    padding-left: 0.8rem;
  }

  .nav-a .sizeColor {
    color: #ff0e0e;
  }

  .pop_img {
    margin-top: .133333rem
      /* 5/37.5 */
    ;
    display: block;
    width: .426667rem
      /* 16/37.5 */
    ;
    height: .426667rem
      /* 16/37.5 */
    ;
    background: url("../../assets/image/wap/icon/8.png") no-repeat;
    background-size: 100% 100%;
  }

  .active {
    width: 0.506667rem
      /* 19/37.5 */
    ;
    height: 0.6667rem
      /* 19/37.5 */
    ;
    background: url("../../assets/image/wap/icon/7.png") no-repeat center;
    background-size: contain;
    position: relative;
  }

  .pop_list {
    height: 0;
    overflow: hidden;
    animation: poplistmy 0.4s linear both;
  }

  @keyframes poplistmy {
    from {
      height: 0px;
    }

    to {
      height: 25rem;
      /* 500/20 */
    }
  }

  .pop_list_li {
    display: flex;
    color: #fff;
    font-size: 0.426667rem
      /* 16/37.5 */
    ;
    padding-top: 0.613333rem
      /* 23/37.5 */
    ;
    padding-bottom: 0.613333rem
      /* 23/37.5 */
    ;
    border-bottom: 1px solid #6c6b6b;
    align-items: center;

    .navIco0 {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.586667rem
        /* 22/37.5 */
      ;
      background: url("../../assets/image/wap/H5/1588.png") no-repeat center;
      background-size: cover;
    }

    .navIco1 {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.586667rem
        /* 22/37.5 */
      ;
      background: url("../../assets/image/wap/H5/159.png") no-repeat center;
      background-size: cover;
    }

    .navIco2 {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.586667rem
        /* 22/37.5 */
      ;
      background: url("../../assets/image/wap/H5/160.png") no-repeat center;
      background-size: cover;
    }

    .navIco3 {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.586667rem
        /* 22/37.5 */
      ;
      background: url("../../assets/image/wap/H5/162.png") no-repeat center;

      background-size: cover;
    }

    .navIco4 {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.586667rem
        /* 22/37.5 */
      ;
      background: url("../../assets/image/wap/H5/161.png") no-repeat center;
      background-size: cover;
    }

    .navIco5 {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.586667rem
        /* 22/37.5 */
      ;
      background: url("../../assets/image/wap/H5/158.png") no-repeat center;
      background-size: contain;
    }

    .navIco6 {
      width: 0.613333rem
        /* 23/37.5 */
      ;
      height: 0.586667rem
        /* 22/37.5 */
      ;

      background: url("../../assets/image/wap/H5/158.png") no-repeat center;
      background-size: contain;
    }
  }

  .pop_list_li>div:nth-child(1) {
    width: 0.533333rem
      /* 20/37.5 */
    ;
    margin-left: 5rem;
    margin-right: 0.666667rem
      /* 25/37.5 */
    ;
    display: flex;
    justify-content: center;
  }

  .pop_list>div:nth-child(6) {
    color: #cc5341;
    font-weight: bold;
    margin-left: -1rem;
  }

  .pop_list>div:last-child {
    color: #cc5341;
    font-weight: bold;
    margin-left: -1rem;
    // font-size: 0.4rem;
  }

  // .tex{
  //   font-size: 0.426667rem /* 16/37.5 */;
  //   padding-left: 5.45rem;
  // }
  .sizeChoice {
    // color: #f1412a;
  }

  .sign {
    width: 2.56rem
      /* 96/37.5 */
    ;
    height: 2.56rem
      /* 96/37.5 */
    ;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    // background: url("../images/web/sign.png") no-repeat center;
    background-size: cover;
    animation: myfirst 2s ease-in-out both infinite;
  }

  @keyframes myfirst {
    0% {
      transform: translateY(-0.4rem
          /* 15/37.5 */
        );
      opacity: 0;
    }

    50% {
      transform: translateY(0.266667rem
          /* 10/37.5 */
        );
      opacity: 1;
    }

    100% {
      transform: translateY(-0.4rem
          /* 15/37.5 */
        );
      opacity: 0;
    }
  }
}
</style>
