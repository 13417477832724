// 企业微信
export const qywx = /wxwork/i.test(navigator.userAgent)
//微信
export const WXTest = /micromessenger/i.test(navigator.userAgent)
// qq
// export const qq = /MQQBrowser/i.test(navigator.userAgent)
export const qq = navigator.userAgent.indexOf("QQTheme") > -1;
// export const qq = navigator.userAgent.indexOf("MQQBrowser") > -1 || navigator.userAgent.indexOf("QQTheme") > -1;

// ios机型
export const iosModel = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)