import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/ldy',
    name: 'ldy',
    component: () => import('../views/ldyView.vue')
  },
  {
    path: '/yinsi',
    name: 'yinsi',
    component: () => import('../components/pc_components/yinsi')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../components/popularize/agreement.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
