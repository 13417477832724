var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"navigation"}},[_c('div',{staticClass:"wap_index-nav"},[_c('div',{staticClass:"wap_index-nav-box"},[_c('div',{staticClass:"img_nav"}),_c('div',{staticClass:"wap_index-nav-right"},[_c('div',{ref:"control",staticClass:"wap_index-nav-box-1"},[_c('div',{ref:"moveNav",staticClass:"flex"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"nav-a",on:{"click":function($event){index === _vm.navList.length - 2 ? _vm.goToWebsite() : index === _vm.navList.length - 1 ? _vm.goToGfsite() : _vm.navChoice(index)}}},[_c('div',{staticClass:"nav_bgSize",class:item.show ? `pop_navIco${index}1` : `pop_navIco${index}`}),_c('div',{class:item.show ? 'sizeColor' : '' || index == _vm.sred ? 'sizeColor' : ''},[_vm._v(_vm._s(item.title))])])}),0)])]),_c('div',{staticClass:"pop_img",class:_vm.isShow ? 'active' : '',on:{"click":_vm.ispopShow}})])]),_c('transition',{attrs:{"enter-active-class":"nav_pop_enter","leave-active-class":"nav_pop_leave"}},[(_vm.popShow)?_c('div',{staticClass:"nav_pop"},[_c('div',{staticClass:"nav_pop-box"},[_c('div',{staticClass:"pop_list",class:_vm.popShow ? 'pop_listPlay' : ''},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"pop_list_li",class:index == _vm.navList.length - 1 ? 'borderNo' : '',on:{"click":function($event){index === _vm.navList.length - 2
              ? _vm.goToWebsite()
              : index === _vm.navList.length - 1
                ? _vm.goToGfsite()
                : _vm.navChoice(index)}}},[_c('div',[_c('div',{class:`navIco${index}`})]),_c('div',{class:item.show
              ? 'sizeChoice'
              : '' || index == _vm.sred
                ? 'sizeColor'
                : ''},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }