<template>
    <div class="guide"  v-if="show" @click.stop="close">
        <div class="popup" >
            <img class="img" :src="guideUrl" mode=""></img>
        </div>
    </div>
</template>
<script>
import { qywx} from "@/utils/guidance";
export default {
    data(){
        return{
            show:false,
        }
    },
    computed: {
			guideUrl() {
				let src = require("../../assets/image/components/guide/guide_1.png")
				if (qywx) {
					src = require("../../assets/image/components/guide/guide_2.png")
				}
				return src
			}
		},
        methods:{
            close(){
                this.show= false
            }
        }
}
</script>
<style scoped lang="scss">
 .guide{
    position: fixed;
    z-index: 99999;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    .popup{
		display: flex;
		.img {
            margin-top: .266667rem /* 10/37.5 */;
            margin-right: .666667rem /* 25/37.5 */;
			margin-left: auto;
            width: 5.6rem /* 210/37.5 */;
            height: 5.2rem /* 195/37.5 */;
		}
	}
 }
</style>