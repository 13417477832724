<template>
  <div id="newCenter" class="newCenter_box">
    <div class="new_title">资讯中心</div>
    <div class="new_center_box">
      <div class="new_menu">
        <div class="new_menu_ul">
          <div v-for="(item, index) in menuList" :key="index" @click="changeNav(index)" class="menu_item"
            :class="indexs == index ? 'active' : ''">
            {{ item.title }}
          </div>
        </div>
      </div>
      <!-- <div
        class="new_content"
        v-for="(item, index) in menuList"
        :key="index"
        v-show="index === indexs"
      >
        <div
          class="wap_game_single_special"
          v-for="(each, index) in item.list"
          :key="index"
          @click="wapSingel(each.wz)"
        >
          <div class="wap_game_single_special_left">
            <img :src="each.url" alt />
          </div>
          <div class="wap_game_single_special_right">
            <div>{{ each.head }}</div>
            <div>{{ each.info }}</div>
          </div>
        </div>
        <div v-if="!item.list || item.list.length === 0">
          <div class="empty_img">
            <img
              src="../../assets/image/wap/banner/709ca4f38368be34aa413e73e2550417743d8a00fb07-yydiFy.png"
              alt
            />
          </div>
          <div class="empty_tex">暂无内容</div>
        </div>
      </div> -->
      <!-- <img src="../../assets/image/wap/banner/1.png" alt="" /> -->
      <div class="swiper newCenter_swiper1">
        <div class="swiper-wrapper">
          <div class="new_content swiper swiper-slide stop-swiping" v-for="(item, index) in menuList" :key="index"
            :class="`game_swiper_public${index}`">
            <div class="swiper-wrapper">
              <div class="wap_game_single_special swiper-slide" v-for="(each, eachIndex) in item.list" :key="eachIndex">
                <div class="item_slide" v-for="(present, presentIndex) in each" :key="presentIndex"
                  @click="wapSingel(present.wz)">
                  <div class="left">
                    <img :src="present.url" alt />
                  </div>
                  <div class="right">
                    <div>{{ present.head }}</div>
                    <div>{{ present.info }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!item.list || item.list.length === 0">
              <div class="empty_img">
                <img src="../../assets/image/wap/banner/709ca4f38368be34aa413e73e2550417743d8a00fb07-yydiFy.png" alt />
              </div>
              <div class="empty_tex">暂无内容</div>
            </div>
            <div class="swiper-pagination" v-if="item.list.length > 1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexs: 0,
      isShow: false,
      menuList: [
        {
          title: "全部资讯",
          show: true,
          list: [
            [
              {
                show: false,
                head: "双十一宠粉节丨千年扑克牌大放送，云挂机购买即送时长！",
                url: require("@/assets/image/wap/banner/s11a.jpg"),
                info: "11月10日-11月16日，来《千年游戏盒子》，签到积分翻倍，云挂机买即送时长，扑克牌限量大放送！",
                time: "2024-11-8",
                wz: "https://mp.weixin.qq.com/s/2G7ZeGmCYiTs_qsrCqgB7A",
              },
              {
                show: false,
                head: "《千年游戏盒子》VIP系统正式上线！超多会员权益，邀您共启千年VIP之旅！",
                url: require("@/assets/image/wap/banner/ydxw.jpg"),
                info: "千年游戏盒子全新VIP等级系统正式上线啦~专属游戏礼包、充值折扣、云挂机......超多VIP专属福利、特权等级越高，福利越多！",
                time: "2024-10-25",
                wz: "https://mp.weixin.qq.com/s/Gz53m3v4qcSBVigunkGpYA",
              },
              {
                show: false,
                head: "《千年游戏盒子》PC端新版本2024年10月17日正式上线！",
                url: require("@/assets/image/wap/banner/1012.jpg"),
                info: "10月17日-20日来千年盒子领取新版本大礼包！游戏礼包、扑克牌、云挂机15日VIP卡免费大放送，诚邀广大千年侠客下载体验！",
                time: "2024-10-12",
                wz: "https://mp.weixin.qq.com/s/6VTCfXCFU-5o1ojbhHHm7Q",
              },
              {
                show: false,
                head: "国庆嗨翻天，好礼多重奏！",
                url: require("@/assets/image/wap/banner/gqs.jpg"),
                info: "10月1日-10月7日，来《千年游戏盒子》，千年游戏礼包、定制扑克送不停，更有9.9元月卡限量抢购！",
                time: "  2024-9-23",
                wz: "https://mp.weixin.qq.com/s/lEPv2hxGm0ec7b-EDY98ZQ",
              },




            ],
            [
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/jqjy.jpg"),
                info: "8月30日-9月3日，《千年游戏盒子》签到积分翻倍！8月30日起，千年盒子游戏礼包大换新，更有千年云挂机第N波福利来袭，快来领取兑换吧~",
                time: "  2024-8-27",
                wz: "https://mp.weixin.qq.com/s/DIQ9AfH0505vfgO0qBQtOg",
              },
              {
                show: false,
                head: "千年盒子PC(电脑）端下载教程",
                url: require("@/assets/image/wap/banner/new-2.jpg"),
                info: "千年盒子PC（电脑）端将于2024年8月13日开启限量内测，届时将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "2024-8-10",
                wz: "https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw",
              },
              {
                show: false,
                head: "《千年游戏盒子》携手<千年定制版香薰>伴您过七夕！",
                url: require("@/assets/image/wap/banner/zxqx.jpg"),
                info: "爱在七夕，赠你甜蜜！为感谢侠客们对《千年游戏盒子》的支持和厚爱，我们精心准备了520套 “千年定制版车载香薰” ，数量有限，先到先得！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/836nCSF0PfGnW8YiQ4Jj2w",
              },
              {
                show: false,
                head: "《千年游戏盒子》庆祝祖国八一建军节特别活动",
                url: require("@/assets/image/wap/banner/zxjj.jpg"),
                info: "值此建军佳节，《千年游戏盒子》特推出签到积分翻倍、云挂机15日VIP卡免费送活动，致敬伟大中国人民解放军！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/iCvaeVBwQk5Y_6DahnL8fw",
              },


            ],
            [
              {
                show: false,
                head: "千年游戏盒子PC(电脑）端与您相约8月！",
                url: require("@/assets/image/wap/banner/pchz.png"),
                info: "千年盒子PC（电脑）端将于2024年8月初开启限量内测，8月初我们将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-7-25",
                wz: "https://mp.weixin.qq.com/s/3f7J87OPvCtbykbR5izsNQ",
              },

              {
                show: false,
                head: "千年盒子官方福利：免费赠送3天云挂机VIP卡",
                url: require("@/assets/image/wap/banner/hz.png"),
                info: "千年游戏盒子PC（电脑）端即将开启对外内测！官方特准备了10000张云挂机3天VIP卡免费赠送给各位玩家,'助'您千年之路事半功倍！数量有限，先到先得，送完即止！",
                time: "  2024-7-16",
                wz: "https://mp.weixin.qq.com/s/aVSqvXaqN2sM_tgtNirLLQ",
              },
              {
                show: false,
                head: "喜讯：千年游戏盒子上线咯",
                url: require("@/assets/image/wap/banner/4.png"),
                info: "回首那些年，经典的千年游戏曾经陪伴我们度过了无数个青春岁月。它不仅仅是游戏，更是我们童年的记忆，是我们与好友一起嬉戏的见证。然而，时间的流转让这些游戏逐渐随风而去，我们只能默默怀念，怀念那段美好而难忘的时光...",
                time: " 2023-11-20",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483889&idx=1&sn=a32733612696bb324ad7d9ce5ba7b26e&chksm=c0f77788f780fe9ed577648185bab9825f0dbfcd54d8a639c049a9f8dc9f85dd984ed4d1fbcd#rd",
              },
              {
                show: false,
                head: "千年游戏盒子福利一览",
                url: require("@/assets/image/wap/banner/5.png"),
                info: "千年游戏盒子作为一站式游戏平台，集成了多个版本的千年游戏，让您无需在不同平台之间切换，只需在千年游戏盒子中选择游戏启动即可,千年游戏盒子注重玩家的账号和个人信息安全，采取了多种安全措施，防止恶意攻击和信息泄露....",
                time: "2023-11-13 ",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483857&idx=1&sn=f4a9a109e58430498cebfecd0d99c680&chksm=c0f777a8f780febeff7e4c2b1a2fa2a9126df15a57d69f6c1bb59af812ca4904db60e27b5ceb#rd",
              },



            ],
            [
              {
                show: true,
                head: "致侠客们的一封信：10月13日19:00 特殊新服《中国大陆独代区》",
                url: require("@/assets/image/wap/banner/2.png"),
                info: "《中国大陆独代区》 对我们，对《千年》IP都意义非凡，在产品推广，产品运营，内容策划上我们都会尽最大努力做好，不失绿色服宗旨，给所有喜爱千年的侠客们一个真正的情怀之所… … ",
                time: " 2023-10-05",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484817&idx=1&sn=531fd768d9500c4f89d75799b4c52f6e&chksm=cf7d7692f80aff842fbc3ee6d926b8c34ad79b9fc1db218092405cd58506c248c7758810fb90#rd",
              },

              {
                show: false,
                head: "《千年游戏盒子》福利加码，千年定制扑克牌礼盒免费大放送！",
                url: require("@/assets/image/wap/banner/new8.jpg"),
                info: "8月20日-31日，《千年游戏盒子》960份千年定制版扑克牌免费兑换，更有66份惊喜随机放送！积分兑换，趣味互动，好礼送不停~",
                time: "2024-8-20",
                wz: "https://mp.weixin.qq.com/s/N0V3L5tbZEo5MpCWE_DJ1w",
              },
              {
                show: false,
                head: "喜讯：《千年》网络游戏独家代理公告",
                url: require("@/assets/image/wap/banner/1.png"),
                info: "《千年》网络游戏在中国大陆地区独占性著作权相关权利，现诚邀各位合作伙伴加入我们，我们相信只有共同努力，多方合作，才能打造好千年游戏IP，为广大玩家提供更优质的游戏体验… … ",
                time: " 2023-09-15",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484779&idx=1&sn=be3e2351561b91a5b47a3b3d6fe86dca&chksm=cf7d7668f80aff7ed5ee73c5ec9b314cb328d6c2b02c402a4ba85cd5f1bf437dd709f1c5971a#rd",
              },
              {
                show: false,
                head: "感恩回馈“千年定制版车载香薰”免费送咯",
                url: require("@/assets/image/wap/banner/3.png"),
                info: "千年盛世绿色版 自7月22日公测以来受到了广大千年侠客的喜爱和支持，非常感谢侠客们对我们的支持和厚爱，我们为此精心准备了600套 “千年定制版车载香薰” 回馈各位侠客,聊表心意… … ",
                time: " 2023-08-27",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484656&idx=1&sn=b43f0d1738b9f4808062cf3b47fd94eb&chksm=cf7d77f3f80afee51f25eebe4665aa22b73167f2832a8619dc8e7262405111105acf9c254a28#rd",
              },

            ],
            [
              {
                show: false,
                head: "一篇文章带您了解千年游戏史！",
                url: require("@/assets/image/wap/banner/256-144.jpg"),
                info: "回味经典千年，致敬千年情怀！带您一键了解千年发展史，前往千年盒子参与活动更有云挂机好礼相赠！",
                time: "2024-8-21",
                wz: "https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw",
              },
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/256-144o.jpg"),
                info: "中秋习俗大赏，9月16日-9月18日，每天12点，来《千年游戏盒子》，1积分抢千年定制扑克牌，云挂机15日VIP卡，数量有限，先到先得！",
                time: "  2024-9-16",
                wz: "https://mp.weixin.qq.com/s/90jEvtl5JpBA1hiKfxMyiA",
              }
            ]
          ],
        },
        {
          title: "公告",
          show: false,
          list: [
            [
              {
                show: false,
                head: "双十一宠粉节丨千年扑克牌大放送，云挂机购买即送时长！",
                url: require("@/assets/image/wap/banner/s11a.jpg"),
                info: "11月10日-11月16日，来《千年游戏盒子》，签到积分翻倍，云挂机买即送时长，扑克牌限量大放送！",
                time: "2024-11-8",
                wz: "https://mp.weixin.qq.com/s/2G7ZeGmCYiTs_qsrCqgB7A",
              },
              {
                show: false,
                head: "《千年游戏盒子》VIP系统正式上线！超多会员权益，邀您共启千年VIP之旅！",
                url: require("@/assets/image/wap/banner/ydxw.jpg"),
                info: "千年游戏盒子全新VIP等级系统正式上线啦~专属游戏礼包、充值折扣、云挂机......超多VIP专属福利、特权等级越高，福利越多！",
                time: "2024-10-25",
                wz: "https://mp.weixin.qq.com/s/Gz53m3v4qcSBVigunkGpYA",
              },
              {
                show: false,
                head: "《千年游戏盒子》PC端新版本2024年10月17日正式上线！",
                url: require("@/assets/image/wap/banner/1012.jpg"),
                info: "10月17日-20日来千年盒子领取新版本大礼包！游戏礼包、扑克牌、云挂机15日VIP卡免费大放送，诚邀广大千年侠客下载体验！",
                time: "2024-10-12",
                wz: "https://mp.weixin.qq.com/s/6VTCfXCFU-5o1ojbhHHm7Q",
              },
              {
                show: false,
                head: "国庆嗨翻天，好礼多重奏！",
                url: require("@/assets/image/wap/banner/gqs.jpg"),
                info: "10月1日-10月7日，来《千年游戏盒子》，千年游戏礼包、定制扑克送不停，更有9.9元月卡限量抢购！",
                time: "  2024-9-23",
                wz: "https://mp.weixin.qq.com/s/lEPv2hxGm0ec7b-EDY98ZQ",
              },




            ],
            [
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/jqjy.jpg"),
                info: "8月30日-9月3日，《千年游戏盒子》签到积分翻倍！8月30日起，千年盒子游戏礼包大换新，更有千年云挂机第N波福利来袭，快来领取兑换吧~",
                time: "  2024-8-27",
                wz: "https://mp.weixin.qq.com/s/DIQ9AfH0505vfgO0qBQtOg",
              },
              {
                show: false,
                head: "千年盒子PC(电脑）端下载教程",
                url: require("@/assets/image/wap/banner/new-2.jpg"),
                info: "千年盒子PC（电脑）端将于2024年8月13日开启限量内测，届时将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "2024-8-10",
                wz: "https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw",
              },
              {
                show: false,
                head: "《千年游戏盒子》携手<千年定制版香薰>伴您过七夕！",
                url: require("@/assets/image/wap/banner/zxqx.jpg"),
                info: "爱在七夕，赠你甜蜜！为感谢侠客们对《千年游戏盒子》的支持和厚爱，我们精心准备了520套 “千年定制版车载香薰” ，数量有限，先到先得！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/836nCSF0PfGnW8YiQ4Jj2w",
              },
              {
                show: false,
                head: "《千年游戏盒子》庆祝祖国八一建军节特别活动",
                url: require("@/assets/image/wap/banner/zxjj.jpg"),
                info: "值此建军佳节，《千年游戏盒子》特推出签到积分翻倍、云挂机15日VIP卡免费送活动，致敬伟大中国人民解放军！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/iCvaeVBwQk5Y_6DahnL8fw",
              },


            ],
            [
              {
                show: false,
                head: "千年游戏盒子PC(电脑）端与您相约8月！",
                url: require("@/assets/image/wap/banner/pchz.png"),
                info: "千年盒子PC（电脑）端将于2024年8月初开启限量内测，8月初我们将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-7-25",
                wz: "https://mp.weixin.qq.com/s/3f7J87OPvCtbykbR5izsNQ",
              },

              {
                show: false,
                head: "千年盒子官方福利：免费赠送3天云挂机VIP卡",
                url: require("@/assets/image/wap/banner/hz.png"),
                info: "千年游戏盒子PC（电脑）端即将开启对外内测！官方特准备了10000张云挂机3天VIP卡免费赠送给各位玩家,'助'您千年之路事半功倍！数量有限，先到先得，送完即止！",
                time: "  2024-7-16",
                wz: "https://mp.weixin.qq.com/s/aVSqvXaqN2sM_tgtNirLLQ",
              },
              {
                show: false,
                head: "喜讯：千年游戏盒子上线咯",
                url: require("@/assets/image/wap/banner/4.png"),
                info: "回首那些年，经典的千年游戏曾经陪伴我们度过了无数个青春岁月。它不仅仅是游戏，更是我们童年的记忆，是我们与好友一起嬉戏的见证。然而，时间的流转让这些游戏逐渐随风而去，我们只能默默怀念，怀念那段美好而难忘的时光...",
                time: " 2023-11-20",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483889&idx=1&sn=a32733612696bb324ad7d9ce5ba7b26e&chksm=c0f77788f780fe9ed577648185bab9825f0dbfcd54d8a639c049a9f8dc9f85dd984ed4d1fbcd#rd",
              },
              {
                show: false,
                head: "千年游戏盒子福利一览",
                url: require("@/assets/image/wap/banner/5.png"),
                info: "千年游戏盒子作为一站式游戏平台，集成了多个版本的千年游戏，让您无需在不同平台之间切换，只需在千年游戏盒子中选择游戏启动即可,千年游戏盒子注重玩家的账号和个人信息安全，采取了多种安全措施，防止恶意攻击和信息泄露....",
                time: "2023-11-13 ",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483857&idx=1&sn=f4a9a109e58430498cebfecd0d99c680&chksm=c0f777a8f780febeff7e4c2b1a2fa2a9126df15a57d69f6c1bb59af812ca4904db60e27b5ceb#rd",
              },



            ],
            [
              {
                show: true,
                head: "致侠客们的一封信：10月13日19:00 特殊新服《中国大陆独代区》",
                url: require("@/assets/image/wap/banner/2.png"),
                info: "《中国大陆独代区》 对我们，对《千年》IP都意义非凡，在产品推广，产品运营，内容策划上我们都会尽最大努力做好，不失绿色服宗旨，给所有喜爱千年的侠客们一个真正的情怀之所… … ",
                time: " 2023-10-05",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484817&idx=1&sn=531fd768d9500c4f89d75799b4c52f6e&chksm=cf7d7692f80aff842fbc3ee6d926b8c34ad79b9fc1db218092405cd58506c248c7758810fb90#rd",
              },

              {
                show: false,
                head: "《千年游戏盒子》福利加码，千年定制扑克牌礼盒免费大放送！",
                url: require("@/assets/image/wap/banner/new8.jpg"),
                info: "8月20日-31日，《千年游戏盒子》960份千年定制版扑克牌免费兑换，更有66份惊喜随机放送！积分兑换，趣味互动，好礼送不停~",
                time: "2024-8-20",
                wz: "https://mp.weixin.qq.com/s/N0V3L5tbZEo5MpCWE_DJ1w",
              },
              {
                show: false,
                head: "喜讯：《千年》网络游戏独家代理公告",
                url: require("@/assets/image/wap/banner/1.png"),
                info: "《千年》网络游戏在中国大陆地区独占性著作权相关权利，现诚邀各位合作伙伴加入我们，我们相信只有共同努力，多方合作，才能打造好千年游戏IP，为广大玩家提供更优质的游戏体验… … ",
                time: " 2023-09-15",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484779&idx=1&sn=be3e2351561b91a5b47a3b3d6fe86dca&chksm=cf7d7668f80aff7ed5ee73c5ec9b314cb328d6c2b02c402a4ba85cd5f1bf437dd709f1c5971a#rd",
              },
              {
                show: false,
                head: "感恩回馈“千年定制版车载香薰”免费送咯",
                url: require("@/assets/image/wap/banner/3.png"),
                info: "千年盛世绿色版 自7月22日公测以来受到了广大千年侠客的喜爱和支持，非常感谢侠客们对我们的支持和厚爱，我们为此精心准备了600套 “千年定制版车载香薰” 回馈各位侠客,聊表心意… … ",
                time: " 2023-08-27",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484656&idx=1&sn=b43f0d1738b9f4808062cf3b47fd94eb&chksm=cf7d77f3f80afee51f25eebe4665aa22b73167f2832a8619dc8e7262405111105acf9c254a28#rd",
              },

            ],
            [
              {
                show: false,
                head: "一篇文章带您了解千年游戏史！",
                url: require("@/assets/image/wap/banner/256-144.jpg"),
                info: "回味经典千年，致敬千年情怀！带您一键了解千年发展史，前往千年盒子参与活动更有云挂机好礼相赠！",
                time: "2024-8-21",
                wz: "https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw",
              },
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/256-144o.jpg"),
                info: "中秋习俗大赏，9月16日-9月18日，每天12点，来《千年游戏盒子》，1积分抢千年定制扑克牌，云挂机15日VIP卡，数量有限，先到先得！",
                time: "  2024-9-16",
                wz: "https://mp.weixin.qq.com/s/90jEvtl5JpBA1hiKfxMyiA",
              }
            ]
          ],
        },
        {
          title: "攻略",
          show: false,
          list: [],
        },
        {
          title: "活动",
          show: false,
          list: [
            [
              {
                show: false,
                head: "双十一宠粉节丨千年扑克牌大放送，云挂机购买即送时长！",
                url: require("@/assets/image/wap/banner/s11a.jpg"),
                info: "11月10日-11月16日，来《千年游戏盒子》，签到积分翻倍，云挂机买即送时长，扑克牌限量大放送！",
                time: "2024-11-8",
                wz: "https://mp.weixin.qq.com/s/2G7ZeGmCYiTs_qsrCqgB7A",
              },
              {
                show: false,
                head: "《千年游戏盒子》VIP系统正式上线！超多会员权益，邀您共启千年VIP之旅！",
                url: require("@/assets/image/wap/banner/ydxw.jpg"),
                info: "千年游戏盒子全新VIP等级系统正式上线啦~专属游戏礼包、充值折扣、云挂机......超多VIP专属福利、特权等级越高，福利越多！",
                time: "2024-10-25",
                wz: "https://mp.weixin.qq.com/s/Gz53m3v4qcSBVigunkGpYA",
              },
              {
                show: false,
                head: "《千年游戏盒子》PC端新版本2024年10月17日正式上线！",
                url: require("@/assets/image/wap/banner/1012.jpg"),
                info: "10月17日-20日来千年盒子领取新版本大礼包！游戏礼包、扑克牌、云挂机15日VIP卡免费大放送，诚邀广大千年侠客下载体验！",
                time: "2024-10-12",
                wz: "https://mp.weixin.qq.com/s/6VTCfXCFU-5o1ojbhHHm7Q",
              },
              {
                show: false,
                head: "国庆嗨翻天，好礼多重奏！",
                url: require("@/assets/image/wap/banner/gqs.jpg"),
                info: "10月1日-10月7日，来《千年游戏盒子》，千年游戏礼包、定制扑克送不停，更有9.9元月卡限量抢购！",
                time: "  2024-9-23",
                wz: "https://mp.weixin.qq.com/s/lEPv2hxGm0ec7b-EDY98ZQ",
              },




            ],
            [
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/jqjy.jpg"),
                info: "8月30日-9月3日，《千年游戏盒子》签到积分翻倍！8月30日起，千年盒子游戏礼包大换新，更有千年云挂机第N波福利来袭，快来领取兑换吧~",
                time: "  2024-8-27",
                wz: "https://mp.weixin.qq.com/s/DIQ9AfH0505vfgO0qBQtOg",
              },
              {
                show: false,
                head: "千年盒子PC(电脑）端下载教程",
                url: require("@/assets/image/wap/banner/new-2.jpg"),
                info: "千年盒子PC（电脑）端将于2024年8月13日开启限量内测，届时将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "2024-8-10",
                wz: "https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw",
              },
              {
                show: false,
                head: "《千年游戏盒子》携手<千年定制版香薰>伴您过七夕！",
                url: require("@/assets/image/wap/banner/zxqx.jpg"),
                info: "爱在七夕，赠你甜蜜！为感谢侠客们对《千年游戏盒子》的支持和厚爱，我们精心准备了520套 “千年定制版车载香薰” ，数量有限，先到先得！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/836nCSF0PfGnW8YiQ4Jj2w",
              },
              {
                show: false,
                head: "《千年游戏盒子》庆祝祖国八一建军节特别活动",
                url: require("@/assets/image/wap/banner/zxjj.jpg"),
                info: "值此建军佳节，《千年游戏盒子》特推出签到积分翻倍、云挂机15日VIP卡免费送活动，致敬伟大中国人民解放军！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/iCvaeVBwQk5Y_6DahnL8fw",
              },


            ],
            [
              {
                show: false,
                head: "千年游戏盒子PC(电脑）端与您相约8月！",
                url: require("@/assets/image/wap/banner/pchz.png"),
                info: "千年盒子PC（电脑）端将于2024年8月初开启限量内测，8月初我们将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-7-25",
                wz: "https://mp.weixin.qq.com/s/3f7J87OPvCtbykbR5izsNQ",
              },

              {
                show: false,
                head: "千年盒子官方福利：免费赠送3天云挂机VIP卡",
                url: require("@/assets/image/wap/banner/hz.png"),
                info: "千年游戏盒子PC（电脑）端即将开启对外内测！官方特准备了10000张云挂机3天VIP卡免费赠送给各位玩家,'助'您千年之路事半功倍！数量有限，先到先得，送完即止！",
                time: "  2024-7-16",
                wz: "https://mp.weixin.qq.com/s/aVSqvXaqN2sM_tgtNirLLQ",
              },
              {
                show: false,
                head: "喜讯：千年游戏盒子上线咯",
                url: require("@/assets/image/wap/banner/4.png"),
                info: "回首那些年，经典的千年游戏曾经陪伴我们度过了无数个青春岁月。它不仅仅是游戏，更是我们童年的记忆，是我们与好友一起嬉戏的见证。然而，时间的流转让这些游戏逐渐随风而去，我们只能默默怀念，怀念那段美好而难忘的时光...",
                time: " 2023-11-20",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483889&idx=1&sn=a32733612696bb324ad7d9ce5ba7b26e&chksm=c0f77788f780fe9ed577648185bab9825f0dbfcd54d8a639c049a9f8dc9f85dd984ed4d1fbcd#rd",
              },
              {
                show: false,
                head: "千年游戏盒子福利一览",
                url: require("@/assets/image/wap/banner/5.png"),
                info: "千年游戏盒子作为一站式游戏平台，集成了多个版本的千年游戏，让您无需在不同平台之间切换，只需在千年游戏盒子中选择游戏启动即可,千年游戏盒子注重玩家的账号和个人信息安全，采取了多种安全措施，防止恶意攻击和信息泄露....",
                time: "2023-11-13 ",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483857&idx=1&sn=f4a9a109e58430498cebfecd0d99c680&chksm=c0f777a8f780febeff7e4c2b1a2fa2a9126df15a57d69f6c1bb59af812ca4904db60e27b5ceb#rd",
              },



            ],
            [
              {
                show: true,
                head: "致侠客们的一封信：10月13日19:00 特殊新服《中国大陆独代区》",
                url: require("@/assets/image/wap/banner/2.png"),
                info: "《中国大陆独代区》 对我们，对《千年》IP都意义非凡，在产品推广，产品运营，内容策划上我们都会尽最大努力做好，不失绿色服宗旨，给所有喜爱千年的侠客们一个真正的情怀之所… … ",
                time: " 2023-10-05",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484817&idx=1&sn=531fd768d9500c4f89d75799b4c52f6e&chksm=cf7d7692f80aff842fbc3ee6d926b8c34ad79b9fc1db218092405cd58506c248c7758810fb90#rd",
              },

              {
                show: false,
                head: "《千年游戏盒子》福利加码，千年定制扑克牌礼盒免费大放送！",
                url: require("@/assets/image/wap/banner/new8.jpg"),
                info: "8月20日-31日，《千年游戏盒子》960份千年定制版扑克牌免费兑换，更有66份惊喜随机放送！积分兑换，趣味互动，好礼送不停~",
                time: "2024-8-20",
                wz: "https://mp.weixin.qq.com/s/N0V3L5tbZEo5MpCWE_DJ1w",
              },
              {
                show: false,
                head: "喜讯：《千年》网络游戏独家代理公告",
                url: require("@/assets/image/wap/banner/1.png"),
                info: "《千年》网络游戏在中国大陆地区独占性著作权相关权利，现诚邀各位合作伙伴加入我们，我们相信只有共同努力，多方合作，才能打造好千年游戏IP，为广大玩家提供更优质的游戏体验… … ",
                time: " 2023-09-15",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484779&idx=1&sn=be3e2351561b91a5b47a3b3d6fe86dca&chksm=cf7d7668f80aff7ed5ee73c5ec9b314cb328d6c2b02c402a4ba85cd5f1bf437dd709f1c5971a#rd",
              },
              {
                show: false,
                head: "感恩回馈“千年定制版车载香薰”免费送咯",
                url: require("@/assets/image/wap/banner/3.png"),
                info: "千年盛世绿色版 自7月22日公测以来受到了广大千年侠客的喜爱和支持，非常感谢侠客们对我们的支持和厚爱，我们为此精心准备了600套 “千年定制版车载香薰” 回馈各位侠客,聊表心意… … ",
                time: " 2023-08-27",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484656&idx=1&sn=b43f0d1738b9f4808062cf3b47fd94eb&chksm=cf7d77f3f80afee51f25eebe4665aa22b73167f2832a8619dc8e7262405111105acf9c254a28#rd",
              },

            ],
            [
              {
                show: false,
                head: "一篇文章带您了解千年游戏史！",
                url: require("@/assets/image/wap/banner/256-144.jpg"),
                info: "回味经典千年，致敬千年情怀！带您一键了解千年发展史，前往千年盒子参与活动更有云挂机好礼相赠！",
                time: "2024-8-21",
                wz: "https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw",
              },
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/256-144o.jpg"),
                info: "中秋习俗大赏，9月16日-9月18日，每天12点，来《千年游戏盒子》，1积分抢千年定制扑克牌，云挂机15日VIP卡，数量有限，先到先得！",
                time: "  2024-9-16",
                wz: "https://mp.weixin.qq.com/s/90jEvtl5JpBA1hiKfxMyiA",
              }
            ]
          ],
        },
      ],
    };
  },
  created() { },
  mounted() {
    this.initSwiper();
  },
  methods: {
    changeNav(index) {
      this.indexs = index;
      this.menuList.forEach((item) => {
        item.show = false;
      });
      this.menuList[index].show = true;
      this.gameSwiper.slideToLoop(index, 1000, false);
    },
    wapSingel(url) {
      if (!url) {
        return;
      }
      const dowa = document.createElement("a");
      dowa.href = url;
      dowa.target = "_blank";
      document.body.appendChild(dowa);
      dowa.click();
      document.body.removeChild(dowa);
    },
    // 初始化swiper
    initSwiper() {
      this.gameSwiper = new Swiper(".newCenter_swiper1", {
        centeredSlides: true,
        // initialSlide: index,
        noSwiping: true,
        noSwipingClass: "stop-swiping",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
      this.gameSwiperList = [];
      this.menuList.forEach((item, index) => {
        let swiper = new Swiper(`.game_swiper_public${index}`, {
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "my-bullet1",
            clickableClass: "my-pagination-clickable",
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            touchEnd: function (swiper, event) {
              // console.log("出发蓝");
            },
          },
        });

        this.gameSwiperList.push(swiper);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.newCenter_box {
  width: 100%;
  height: 100%;
  // background-color: palegoldenrod;
  background: url(../../assets/image/wap/bg2/1.png) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .new_title {
    font-size: 0.533333rem
      /* 20/37.5 */
    ;
    color: #393333;
    font-weight: bold;
    padding-top: 1.83333rem
      /* 20/37.5 */
    ;
    padding-bottom: 0.533333rem;
  }

  //内容
  .new_content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.533333rem
      /* 20/37.5 */
    ;
    flex-direction: column;
  }

  .new_center_box {
    width: 100%;
    height: 100%;
    // background: #ffffff;

    // margin-top: 0.586667rem /* 22/37.5 */;
    box-sizing: border-box;

    .new_menu {
      height: 0.746667rem
        /* 28/37.5 */
      ;
      width: 9.466667rem
        /* 355/37.5 */
      ;
      border-bottom: 0.053333rem
        /* 2/37.5 */
        solid #949494;
      text-align: center;
      line-height: 0.375rem;
      color: #000000;
      //   font-weight: bold;
      font-size: 0.426667rem
        /* 16/37.5 */
      ;
      margin: 0 auto;

      .new_menu_ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: 0.161458rem
          /* 31/192 */
        ;

        .menu_item {
          //   margin-left: 0.641667rem;
          cursor: pointer;
        }

        .active {
          // color: red;
          position: relative;
        }

        .active::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.42rem;
          width: 100%;
          height: 0.053333rem
            /* 2/37.5 */
          ;
          background: #e14b4b;
          border-radius: 0.026667rem;
          /* 1/37.5 */
        }
      }
    }

    .wap_game_single_special {
      display: flex;
      margin-bottom: 0.533333rem
        /* 20/37.5 */
      ;
      flex-direction: column;
      align-items: center;
    }

    .item_slide {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.45rem
        /* 31/192 */
      ;
    }

    .left {
      width: 2.986667rem
        /* 112/37.5 */
      ;
      height: 1.68rem
        /* 63/37.5 */
      ;
      border-radius: 0.106667rem
        /* 4/37.5 */
      ;
      margin-right: 0.346667rem
        /* 13/37.5 */
      ;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 0.2rem
          /* 28/16 */
        ;
      }
    }

    .right {
      width: 5.866667rem
        /* 220/37.5 */
      ;
    }

    .right>div:nth-child(1) {
      color: #393333;
      font-size: 0.373333rem
        /* 14/37.5 */
      ;
      padding-bottom: 0.07rem
        /* 10/37.5 */
      ;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    .right>div:nth-child(2) {
      color: #393333;
      font-size: 0.373333rem
        /* 14/37.5 */
      ;
      height: 1.013333rem
        /* 38/37.5 */
      ;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .empty_img {
      width: 6.346667rem
        /* 238/37.5 */
      ;

      height: 6.346667rem
        /* 238/37.5 */
      ;

      position: relative;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .empty_tex {
      text-align: center;
      color: #949494;

      font-size: 0.533333rem
        /* 20/37.5 */
      ;
    }
  }
}

// swiper
.newCenter_swiper {
  flex: 1;
  width: 100%;
  box-sizing: border-box;

  .new_content_box {
    width: 100%;
    height: 100%;
  }
}

.empty_tex {
  text-align: center;
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #949494;
}
</style>
<style>
.newCenter_swiper1 .my-bullet1+.my-bullet1 {
  margin-left: 0.2rem;
}

.newCenter_swiper1 .my-bullet1 {
  display: inline-block;
  width: 0.2rem
    /* 9/192 */
  ;
  height: 0.2rem
    /* 9/192 */
  ;
  border-radius: 50%;
  background-color: #fffefe !important;
}

.newCenter_swiper1 .swiper-pagination-bullet-active {
  background-color: #cc5341 !important;
}

.newCenter_swiper1 .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0.15rem;
  left: 2%;
}
</style>
